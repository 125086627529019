import urlBuilder from "@sanity/image-url";
import { useEffect } from "react";
import { useRouter } from "next/router";

import { useVariant } from "@chef/feature-flags";
import { useDiscount } from "@chef/state-management/hooks";
import { BRAND_NAME } from "@chef/constants";
import { useGetTraitsQuery, useMeQuery } from "@chef/state-management";

import { IExperiment, IContent, IPersonal } from "../types";
import { getClient } from "../utils";
import { BlockContent } from "./BlockContent";
import { Carousel } from "./Carousel";

type Primitive = string | number | boolean | null | undefined;

interface ChildrenFnProps {
  title: string;
  description?: string;
  imageUrl?: string;
  index?: boolean;
  children: React.ReactNode;
}

interface ContentComponentProps {
  block?: IExperiment | IContent | IPersonal;
  children?: ((props: ChildrenFnProps) => React.ReactNode) | React.ReactNode;
}

interface ExperimentComponentProps {
  block: IExperiment;
  children?: ((props: ChildrenFnProps) => React.ReactNode) | React.ReactNode;
}

interface PersonalComponentProps {
  block: IPersonal;
  children?: ((props: ChildrenFnProps) => React.ReactNode) | React.ReactNode;
}

interface InnerContentComponentProps {
  block: IContent;
  children?: ((props: ChildrenFnProps) => React.ReactNode) | React.ReactNode;
}

const prefix = BRAND_NAME.toLowerCase();

const match = (
  a: Primitive,
  b: string,
  op: "==" | "!=" | ">" | "<" | ">=" | "<=",
) => {
  if (a === undefined) {
    return false;
  }

  const eq = (a: string, b: string) => a.toString() === b;
  const neq = (a: string, b: string) => a.toString() !== b;
  const gt = (a: number, b: string) => a > +b;
  const lt = (a: number, b: string) => a < +b;
  const gte = (a: number, b: string) => a >= +b;
  const lte = (a: number, b: string) => a <= +b;

  switch (typeof a) {
    case "string":
      switch (op) {
        case "==":
          return eq(a, b);
        case "!=":
          return neq(a, b);
        case ">":
          return gt(+a, b);
        case "<":
          return lt(+a, b);
        case ">=":
          return gte(+a, b);
        case "<=":
          return lte(+a, b);
        default:
          return false;
      }
    case "number":
      switch (op) {
        case "==":
          return eq(a.toString(), b);
        case "!=":
          return neq(a.toString(), b);
        case ">":
          return gt(a, b);
        case "<":
          return lt(a, b);
        case ">=":
          return gte(a, b);
        case "<=":
          return lte(a, b);
        default:
          return false;
      }
    case "boolean":
      switch (op) {
        case "==":
          return eq(a.toString(), b);
        case "!=":
          return neq(a.toString(), b);
        default:
          return false;
      }
    case "undefined":
      return false;
    case "object":
      if (a === null) {
        switch (op) {
          case "==":
            return eq("null", b);
          case "!=":
            return neq("null", b);
          default:
            return false;
        }
      }
      return false;
    default:
      return false;
  }
};

const PersonalComponent = ({ block, children }: PersonalComponentProps) => {
  const { data: getTraitsQuery } = useGetTraitsQuery();

  if (!getTraitsQuery || !getTraitsQuery.traits) {
    return null;
  }

  const traits = getTraitsQuery.traits;

  const blocks = block.items.filter((item) => {
    const conditions = item.conditions;

    if (!conditions) {
      return false;
    }

    switch (item.operator) {
      case "AND":
        return conditions.every((c) => {
          const t = c.trait?.trait.current;

          let trait: Primitive;

          if (t === "true" || t === "false") {
            trait = t;
          } else {
            trait = traits[t];
          }

          return match(trait, c.value, c.operator);
        });
      case "OR":
        return conditions.some((c) => {
          const t = c.trait?.trait.current;

          let trait: Primitive;

          if (t === "true" || t === "false") {
            trait = t;
          } else {
            trait = traits[t];
          }

          return match(trait, c.value, c.operator);
        });
      case "XOR":
        return (
          conditions.filter((c) => {
            const t = c.trait?.trait.current;

            let trait: Primitive;

            if (t === "true" || t === "false") {
              trait = t;
            } else {
              trait = traits[t];
            }

            return match(trait, c.value, c.operator);
          }).length === 1
        );
      default:
        return false;
    }
  });

  if (!blocks.length) {
    return null;
  }

  if (blocks.length === 1) {
    return (
      <ContentComponent block={blocks[0].content}>{children}</ContentComponent>
    );
  }

  return (
    <Carousel name={block._id}>
      {blocks.map((b) => (
        <div
          className="pl-4 embla__slide shrink-0 grow-0 basis-full"
          key={b.content?._id}
        >
          <ContentComponent block={b.content}>{children}</ContentComponent>
        </div>
      ))}
    </Carousel>
  );
};

const ExperimentComponent = ({ block, children }: ExperimentComponentProps) => {
  const flag = `${prefix}_${block.slug?.current}`;
  const variant = useVariant(flag);

  if (!block.slug) {
    return null;
  }

  if (!variant || variant.name === "disabled") {
    console.warn(
      `No variant found for experiment ${block.slug.current} -- rendering control`,
    );

    return (
      <ContentComponent block={block.variants[0]}>{children}</ContentComponent>
    );
  }

  const v = block.variants.find((v) => v._id === variant.name);

  if (!v) {
    console.error(
      "No block-child found for variant",
      variant.name,
      "in flag",
      flag,
    );
    return null;
  }

  return <ContentComponent block={v}>{children}</ContentComponent>;
};

const InnerContentComponent = ({
  block,
  children,
}: InnerContentComponentProps) => {
  const router = useRouter();
  const { data: isLoggedIn } = useMeQuery();
  const { setSid } = useDiscount();

  const client = getClient();

  const imageUrl =
    block.seo.image &&
    urlBuilder(client).image(block.seo.image).width(480).url();

  useEffect(() => {
    if (block.sid?.code) {
      setSid(block.sid.code);
    }
  }, [block.sid, setSid]);

  useEffect(() => {
    if (block.loggedInRedirect && isLoggedIn) {
      router.push(block.loggedInRedirect);
    }
  }, [block.loggedInRedirect, isLoggedIn, router]);

  if (typeof children === "function") {
    return children({
      title: block.seo.title,
      description: block.seo.description,
      index: block.seo.index,
      imageUrl,
      children: (
        <BlockContent
          body={block.body}
          prose={{
            maxWidth: block.prose?.maxWidth,
            padding: block.prose?.padding,
          }}
        />
      ),
    });
  }

  return (
    <BlockContent
      body={block.body}
      prose={{ maxWidth: block.prose?.maxWidth, padding: block.prose?.padding }}
    />
  );
};

export const ContentComponent = ({
  children,
  block,
}: ContentComponentProps) => {
  if (!block) {
    return null;
  }

  if (block._type === "experiment") {
    return <ExperimentComponent block={block}>{children}</ExperimentComponent>;
  }

  if (block._type === "personal") {
    return <PersonalComponent block={block}>{children}</PersonalComponent>;
  }

  return (
    <InnerContentComponent block={block}>{children}</InnerContentComponent>
  );
};
