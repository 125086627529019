import ImageUrlBuilder from "@sanity/image-url";
import { ImageLoader } from "next/image";
import { SanityImageSource } from "@sanity/asset-utils";

import { isEmptyObject } from "@chef/utils/object";
import { config } from "@chef/constants";

import { DummyImageUrlBuilderShim } from "../utils/shim";

export const imageUrlBuilder = ImageUrlBuilder({
  projectId: config.sanity.projectId,
  dataset: config.sanity.dataset,
});

export const createImageUrlBuilder = (source: SanityImageSource) => {
  if (!source || isEmptyObject(source)) {
    return new DummyImageUrlBuilderShim();
  }
  return imageUrlBuilder.image(source);
};

export const imageLoader: ImageLoader = ({ src, width, quality }) => {
  const url = new URL(src);

  url.searchParams.set("w", width.toString());

  if (quality) {
    url.searchParams.set("q", quality.toString());
  }

  return url.toString();
};
